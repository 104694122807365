import React, { Component } from 'react';
// import {Link} from 'gatsby';
// import classNames from 'classnames';
import $ from 'jquery';
import Cookies from 'js-cookie';

import LayoutLPOne from '../components/layoutLPOne';
import SEO from "../components/seo";

import SimpleModal from '../components/utilities/SimpleModal';
import SimpleModalTwo from '../components/utilities/SimpleModalTwo';
import SimpleModalThree from '../components/utilities/SimpleModalThree';
import SimpleModalFour from '../components/utilities/SimpleModalFour';
import FormField from '../components/utilities/FormField';

import './technologymarketing.scss';

class TechnologyForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactCompany: ''
        };

        this.submit = this.submit.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.phoneChange = this.phoneChange.bind(this);
        this.companyChange = this.companyChange.bind(this);
    }

    submit(e) {
        e.preventDefault();
        let formObject = $('#heroForm');
        let postDt = new Date();
        let loadDt = new Date();
        //let _sid = 'qq3eu25egawyabolsjpyzcr5';
        let _sid = Cookies.get('ASP.NET_SessionId');
        if (formObject.find('input[name="js"]').length === 0) {
            let str = '<input type="hidden" name="js" value="1" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="ttp"]').length === 0) {
            let str = '<input type="hidden" name="ttp" value="' + ((postDt - loadDt) / 1000).toString() + '" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="sid"]').length === 0) {
            let str = '<input type="hidden" name="sid" value="' + _sid + '" />';
            formObject.append(str);
        } else {
            formObject.find('input[name="sid"]').val(_sid);
        }

        let post_data = formObject.serialize();

        // console.log(post_data);

        this.setState({
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactCompany: ''
        });

        $.ajax({
            url: "https://metrix.meritmile.com/email.aspx?ajax=1",
            type: 'POST',
            data: post_data,
            cache: false,
            dataType: 'json',
            success: function(data) {
                // Success..
                // let r = data[0];
                // let redirect_url = "https://www.meritmile.com/uploads/2020/08/2020-MedTech-Marketing-and-PR-Trends.pdf";

                // formObject.find('.feedback').show().removeClass('alert-error').addClass('alert-success').html(r.Message).css('display', 'block').focus();
                // window.location.href = redirect_url;

                $('#heroForm').hide();
                $('.form-wrapper .thankyou').show();
                
                window.open(
                    'https://mm-stats.com/cko5v',
                    '_blank' // <- This is what makes it open in a new tab.
                );
            },
            // Fail..
            error: function(result) {
                let r = result[0];

                formObject.find('.feedback').show().addClass('alert-error').html(r.Message).css('display', 'block').focus();
                formObject.find('.submit').attr('disabled', false).removeClass('disabled');
            }
        });
    }

    nameChange(e){
        this.setState({contactName: e.target.value})
    }

    emailChange(e){
        this.setState({contactEmail: e.target.value})
    }

    phoneChange(e){
        this.setState({contactPhone: e.target.value})
    }

    companyChange(e){
        this.setState({contactCompany: e.target.value})
    }

    render() {
        return (
            <form id="heroForm" name="metrixform" method="POST" action="https://metrix.meritmile.com/email.aspx" onSubmit={this.submit}>
                <FormField fieldID={"nameHM"} valChange={this.nameChange} val={this.state.contactName} type={"text"}  name={"name"} label={"Full Name"}/>
                <FormField fieldID={"emailHM"}  valChange={this.emailChange} val={this.state.contactEmail} type={"email"}  name={"email"} label={"Email"}/>
                <FormField fieldID={"companyHM"}  valChange={this.companyChange} val={this.state.contactCompany} type={"text"}  name={"company"} label={"Company"}/>
                <FormField fieldID={"telephoneHM"}  valChange={this.phoneChange} val={this.state.contactPhone} type={"tel"} name={"telephone"} label={"Telephone"}/>
                <button type="submit" className="btn btn-white">Get the Report</button>
                <div className="form-group">
                    <input type="hidden" name="referrer" value="https://www.meritmile.com/technology-marketing/" />
                    <input type="hidden" name="auth" value="d998ca84-edfa-4653-97ec-bccc0c8fe78c" />
                    <input type="text" className="d-none hu" id="human-check" name="human-check" />
                    <div className="feedback"></div>
                    <div className="fix"></div>
                </div>
            </form>
        )
    }
}


export default ({pageContext}) => (
    <LayoutLPOne>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />
        <div className={`${pageContext.slug} technologymarketing-wrapper`}>
            <div className="lp-hero">
                <div className="container margin-b">
                    <div className="row margin-b">
                        <div className="col-md-8 hero-title">
                            <h1>Technology<br />Marketing<br />From Tech,<br />For Tech<span className="bar"></span></h1>
                        </div>
                        <div className="col-md-4 hero-bg">
                            <div className="inside-hero text-center">
                                <h2>Read the top 5 B2B marketing strategies by our service provider experts!</h2>
                                <div className="form-wrapper">
                                    <TechnologyForm />
                                    <div className="thankyou">
                                    <h4>Thank You</h4>
                                    <p>If the report does not immediately open please check your pop-up blocker. A copy will also be sent to your email.</p>
                                    <p>Add info@meritmile.com to your contacts to ensure future communications will not be sent to spam.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row margin-b justify-content-center">
                    <div className="col-12 col-lg-10">
                        <h2 className="bodyTitle">We Are the IT Channel Marketing Experts</h2>
                        <p>Would you hire a software developer to deploy your cloud infrastructure? An electrician to fix your plumbing? Of course not. Your approach to B2B technology marketing shouldn't be any different.</p>
                        <p>We are the IT and channel marketing agency experts – from tech, for tech. Our team of industry veterans has nearly 100 combined years of working in IT channel industry for and with world-class companies like <a href="/case-studies/citrix/">Citrix</a>, Microsoft, DXC, <a href="/case-studies/hpe/">HPE</a>, and many, many others.</p>
                        <p>We've earned our domain expertise and clients' trust through hands-on, industry experience - not just pretty logos on our website.</p>
                    </div>
                </div>
                <div className="row margin-b channels align-items-center align-self-center">
                    <div className="col-6 col-sm-4 col-lg-2">
                        <div className="icon">
                            <img className="img-fluid" src="https://www.meritmile.com/uploads/2019/03/icon-channel-readiness.svg" alt="Channel Readiness" />
                        </div>
                        <h4>Channel Readiness</h4>
                    </div>
                    <div className="col-6 col-sm-4 col-lg-2">
                        <div className="icon">
                            <img className="img-fluid" src="https://www.meritmile.com/uploads/2019/03/icon-content-authoring.svg" alt="Content Creation" />
                        </div>
                        <h4>Content Creation</h4>
                    </div>
                    <div className="col-6 col-sm-4 col-lg-2">
                        <div className="icon">
                            <img className="img-fluid" src="https://www.meritmile.com/uploads/2019/03/icon-go-to-market-strategy.svg" alt="Go-to-Market Strategy" />
                        </div>
                        <h4>Go-to-Market Strategy</h4>
                    </div>
                    <div className="col-6 col-sm-4 col-lg-2">
                        <div className="icon">
                            <img className="img-fluid" src="https://www.meritmile.com/uploads/2019/03/icon-lead-generation-awareness.svg" alt="Lead Generation + Awareness" />
                        </div>
                        <h4>Lead Generation + Awareness</h4>
                    </div>
                    <div className="col-6 col-sm-4 col-lg-2">
                        <div className="icon">
                            <img className="img-fluid" src="https://www.meritmile.com/uploads/2019/03/icon-social-media.svg" alt="Social Media + SEM" />
                        </div>
                        <h4>Social Media + SEM</h4>
                    </div>
                    <div className="col-6 col-sm-4 col-lg-2">
                        <div className="icon">
                            <img className="img-fluid" src="https://www.meritmile.com/uploads/2019/03/icon-video-production.svg" alt="Video Production" />
                        </div>
                        <h4>Video Production</h4>
                    </div>
                </div>
                <div className="row margin-b justify-content-center">
                    <div className="col-12 col-lg-10">
                        <h2>The Marketing of Cloud Migration</h2>
                        <p>Are you wrapping your arms around licensing models like Microsoft CSP (Cloud Solution Provider), Microsoft SPLA (Services Provider License Agreement), and/or the Citrix Service Provider program?</p>
                        <p>Digital transformation can be confusing, but successful adoption of technology and business solutions often start with a strategic marketing plan. When evolving customers to the cloud from on-prem or hybrid environments, a steady commitment to channel partner, service provider and end-user education is a key part of the process. This is especially true when embracing more complex licensing initiatives such as desktop delivery, endpoint security or end of support for Windows Server and SQL.</p>
                        <p>Compel your partners or end-users to move workloads to the cloud with the right message and offer. From Office 365 to Azure migration, partners and users require not only the information, but also the incentives and tools—including training and readiness, demand generation, awareness tactics, content, and, of course, digital marketing.</p>
                        <p>The Merit Mile view is that digital transformation is a macro business strategy, not a micro technology decision. Crafting and executing effective marketing communications around cloud—across all touchpoints on the IT supply chain—is where we do some of our finest work.</p>
                    </div>
                </div>
                <div className="row margin-b justify-content-center">
                    <div className="col-12 col-lg-10">
                        <h2>Your Company is One-of-a-Kind</h2>
                        <p>Have you tried those in-a-box marketing kits and seen sub-par results? Did that syndicated content from your partner fall short of expectations? Does your website and brand stand out from the crowd?</p>
                        <p>Everything about your business is unique – what you stand for, the services you provide, and how you win new customers. So why settle for off-the-shelf, generic looking web content, collateral, and branding?</p>
                        <p>Everything we do is custom built for you and finely tuned for your specific goals. From wowing customers with a video to nailing your prospects pain points in an email, we take the time to tell your unique story.</p>
                    </div>
                </div>
            </div>
            <div className="container-fluid bluebar">
                <div className="row">
                    <div className="col">
                        <h4 className="text-center">How We Can Help You</h4>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row margin-b">
                    <div className="col-lg-6 order-xs-2">
                        <img className="img-fluid max-90" src="https://www.meritmile.com/uploads/2019/03/thumb-go-to-market-planning-strategy.jpg" alt="Go-to-Market Planning & Strateg" />
                    </div>
                    <div className="col-lg-5 order-xs-1">
                        <h3>Go-to-Market Planning & Strategy</h3>
                        <p>The best ideas are only as good as the plan behind them. From strategic-level business and go-to-market planning, to campaigns, promotions, and events, our technology marketing agency can help you develop, execute, and measure your plans. We’ve done it for others now let us do it for you. <a href="/case-studies/anywhereapp/">Read the case study</a>.</p>
                        <ul>
                            <li>Refine your business model for cloud services, identify new addressable markets, develop vertical expertise.</li>
                            <li>Launch new services and products to market with proven and measurable launch plans.</li>
                            <li>Plan and execute trade shows, and in-person, virtual, and training events.</li>
                        </ul>
                    </div>    
                </div>
                <div className="row margin-b">
                    <div className="col-lg-6 order-lg-2">
                        <img className="img-fluid max-90 float-lg-right" src="https://www.meritmile.com/uploads/2019/03/thumb-sales-marketing-content.jpg" alt="Sales and Marketing Content" />
                    </div>
                    <div className="col-lg-5 offset-lg-1 order-lg-1">
                        <h3>Sales and Marketing Content</h3>
                        <p>Presentations, brochures, and datasheets oh my! Marketers need engaging content for campaigns. Customers expect informative and professional looking materials, and sales needs training, competitive positioning, and play-books. Who's going to create all the content? Merit Mile provides end-to-end content creation of:</p>
                        <ul>
                            <li>Infographics, whitepapers, research reports, and thought leadership content.</li>
                            <li>Presentations, data sheets, case studies, and brochures.</li>
                            <li>eLearning, training workshops, sales plays, and battlecards.</li>
                        </ul>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-lg-5 order-xs-2 video-thumbs">
                        <div className="row">
                            <div className="col-6">
                                <img src="https://www.meritmile.com/uploads/2019/03/video-thumb-a.jpg" alt="Video 1" className="img-fluid" onClick={() => this.openModal()} id="playVideo" /> 
                                <SimpleModal videoSRC='vC1UlHmNrzo' />
                            </div>
                            <div className="col-6">
                                <img src="https://www.meritmile.com/uploads/2019/03/video-thumb-b.jpg" alt="Video 2" className="img-fluid" onClick={() => this.openModalTwo()} id="playVideoTwo"/>    
                                <SimpleModalTwo videoSRC='wgPzgcu_jws' />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <img src="https://www.meritmile.com/uploads/2019/03/video-thumb-c.jpg" alt="Video 3" className="img-fluid" onClick={() => this.openModalThree()} id="playVideoThree"/>
                                <SimpleModalThree videoSRC='wHG54VyFJgc' />
                            </div>
                            <div className="col-6">
                                <img src="https://www.meritmile.com/uploads/2019/03/video-thumb-d.jpg" alt="Video 4" className="img-fluid" onClick={() => this.openModalFour()} id="playVideoFour"/>
                                <SimpleModalFour videoSRC='Bzgh03ilNlQ' />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 offset-lg-1 order-xs-1">
                        <h3>Channel & Partner Videos </h3>
                        <p>We all know how pervasive video has become. But did you know 87% of business now use video as a primary marketing tool, up from 63% in 2017. Does your marketing strategy utilize video? We can help you captivate your audience with a range of video styles including:</p>
                        <ul>
                            <li>Animated explainer videos</li>
                            <li>Corporate and product positioning videos</li>
                            <li>Live camera videos</li>
                            <li>Video-based training</li>
                        </ul>
                    </div>    
            </div>
            <div className="row margin-b justify-content-center">
                <div className="col-12 col-lg-10">
                    <h3>B2B Lead-Generation and Channel Recruitment</h3>
                    <p>What do you do when word-of-mouth referrals aren’t enough? Every business must continually fuel the pipeline but doing so is easier said than done. We start by defining specific goals – new customers, channel recruitment, compete, cross-sell, or brand awareness – and build targeted and measurable integrated marketing campaigns. Sent from your email marketing platform or our proprietary web-based platform, we can put your brand in-front of the right audience, with measurable ROI metrics to justify your investment. <a href="/case-studies/hpe/">Read the case study.</a></p>
                </div>
            </div>
            <div className="row margin-b">
                <div className="col-lg-6"><img className="img-fluid max-90" src="https://www.meritmile.com/uploads/2019/03/thumb-b2b.jpg" alt="B2B Lead-Generation and Brand Awareness" /></div>
                <div className="col-lg-5">
                    <ul>
                        <li>Fill the top of your funnel and nurture leads to closure with integrated in-bound and out-bound demand-generation campaigns.</li>
                        <li>Engage your community and grow your social footprint with targeted LinkedIn, Instagram, Facebook, and search engine marketing (SEM) campaigns.</li>
                        <li>Build your brand or channel program through editorial articles and interviews with industry experts, trade publications, and digital communities.</li>
                    </ul>
                </div>
            </div>
            </div>
            <div className="container">
                <div className="row margin-b justify-content-center">
                    <div className="col-12 col-lg-10">
                        <blockquote>The Merit Mile team is a strategic partner. They are an engrained part of our business… bringing new ideas, opportunities, and a level of content and creative expertise that directly impacts our business.” </blockquote>
                        <cite className="blockquote-footer">Kim K. | DXC Technology</cite>
                    </div>
                </div>
            </div>
            <div className="bottom-logos">
                <div className="container">
                    <div className="row">
                        <div className="col d-flex flex-row justify-content-between align-items-center align-self-center">
                            <div className="icon-btm"><img src="https://www.meritmile.com/uploads/2019/03/icon-c3.svg" className="img-fluid" alt="3C Interactive" /></div>
                            <div className="icon-btm"><img src="https://www.meritmile.com/uploads/2019/03/icon-anywhereapp.svg" className="img-fluid" alt="AnywhereApp" /></div>
                            <div className="icon-btm"><img src="https://www.meritmile.com/uploads/2019/03/icon-citrix.svg" className="img-fluid" alt="Citrix" /></div>
                            <div className="icon-btm"><img src="https://www.meritmile.com/uploads/2019/03/icon-cyient.svg" className="img-fluid" alt="Cyient" /></div>
                            <div className="icon-btm"><img src="https://www.meritmile.com/uploads/2019/03/icon-datacore.svg" className="img-fluid"  alt="Datacore" /></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex flex-row justify-content-between align-items-center align-self-center">
                            <div className="icon-btm"><img src="https://www.meritmile.com/uploads/2019/03/icon-dxc.svg" className="img-fluid" alt="DXC" /></div>
                            {/* <div className="icon-btm"><img src="https://www.meritmile.com/uploads/2019/03/icon-google.svg" className="img-fluid" alt="Google" /></div> */}
                            <div className="icon-btm"><img src="https://www.meritmile.com/uploads/2019/03/icon-fujitsu.svg" className="img-fluid" alt="Fujitsu" /></div>
                            <div className="icon-btm"><img src="https://www.meritmile.com/uploads/2019/03/icon-hpe.svg" className="img-fluid" alt="HPE" /></div>
                            <div className="icon-btm"><img src="https://www.meritmile.com/uploads/2019/03/icon-ibm.svg" className="img-fluid"  alt="IBM" /></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex flex-row justify-content-between align-items-center align-self-center">
                            {/* <div className="icon-btm"><img src="https://www.meritmile.com/wp-content/uploads/2019/03/icon-insight.svg" className="img-fluid" alt="Insight" /></div> */}
                            <div className="icon-btm"><img src="https://www.meritmile.com/uploads/2019/03/icon-jdl.svg" className="img-fluid" alt="JDL" /></div>
                            <div className="icon-btm"><img src="https://www.meritmile.com/uploads/2019/03/icon-microsoft.svg" className="img-fluid" alt="Microsoft" /></div>
                            <div className="icon-btm"><img src="https://www.meritmile.com/uploads/2019/03/icon-mtm.svg" className="img-fluid" alt="MTM" /></div>
                            <div className="icon-btm"><img src="https://www.meritmile.com/uploads/2019/03/icon-parallels.svg" className="img-fluid"  alt="Parallels" /></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex flex-row justify-content-between align-items-center align-self-center">
                            {/* <div className="icon-btm"><img src="https://www.meritmile.com/uploads/2019/03/icon-peer1.svg" className="img-fluid" alt="Peer1" /></div> */}
                            <div className="icon-btm"><img src="https://www.meritmile.com/uploads/2019/03/icon-plugable.svg" className="img-fluid" alt="Plugable" /></div>
                            <div className="icon-btm"><img src="https://www.meritmile.com/uploads/2019/03/icon-signavio.svg" className="img-fluid" alt="Signavio" /></div>
                            <div className="icon-btm"><img src="https://www.meritmile.com/uploads/2019/03/icon-softnas.svg" className="img-fluid" alt="SoftNAS" /></div>
                            <div className="icon-btm"><img src="https://www.meritmile.com/uploads/2019/03/icon-wipro.svg" className="img-fluid"  alt="WiPro" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container why-work">
                <div className="row">
                    <div className="col">
                        <h2 className="text-center">Why Work With Merit Mile?</h2>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-lg-5 order-xs-2">
                        <img className="img-fluid max-90" src="https://www.meritmile.com/uploads/2019/03/thumb-why-work.jpg" alt="Fill the top of your funnel" />
                    </div>
                    <div className="col-lg-6 offset-lg-1 order-xs-1">
                        <ul>
                            <li>Experienced and talented marketing individuals dedicate themselves to your goals and see it through to completion.</li>
                            <li>We measure your success based on real data and results, not just how good the market looks.</li>
                            <li>The team's eclectic skills are combined and refined for an artistic creation of functional and thought leadership marketing.</li>
                        </ul>
                        <br />
                        <h3 className="">Start improving your sales pipeline today.</h3>
                        <a className="btn btn-primary btn-lg btn-block" href="#top">LET'S TALK</a>
                    </div>    
                </div>
            </div>
        </div>
    </LayoutLPOne>
)